export enum FeatureFlag {
	EnablePackagesPilet = 'enable-packages-pilet', // Determines whether the solutions pilet functionality is available
	EnableSolutionsSdkV2 = 'enable-solutions-sdk-v2', // If enabled, uses v2 of the solutions API
	EnableTemplateSolutionTab = 'enable-template-solution-tab', // Enables the template solution tab
	EnableIntegrationSolutionTab = 'enable-integration-solution-tab', // Enables the integration solution tab
	EnableSolutionSolutionTab = 'enable-solution-solution-tab', // Enables the solution solution tab
	EnableProductLedGrowth = 'enable_product_led_growth', // Enable PLG banners and modal popups
	EnableSolutionUpgrades = 'enable-solution-upgrades', // Enable solution upgrades
	EnableSolutionDelete = 'enable-solution-delete', // Enables delete button for solutions
	EnableSolutionInstallRequests = 'enable-solution-install-requests', // Enables install requests for solutions
	SelfServiceHipaaMMF2 = 'self_service_hipaa_mmf2', // Enable self-service HIPAA MMF2
	EnablePartnerProfilePage = 'enable_partner_profile_page', // Enable partner profile page
	EnableCatalogFilterSolutions = 'catalog_filter_solutions', // Enables filtering of individual solutions
	EnableClientExperienceChangeModal = 'enable_client_experience_change_modal', // Enables the client experience change modal for certain solutions
	EnableBetaModeTaxPrepSolution = 'enable_beta_mode_tax_prep_solution', // Enables beta mode for the tax prep solution
	EnableSolutionBasedEntitlements = 'enable-solution-based-entitlements', // Enables entitlements upon solution install
	EnableBetaModeLegalSolution = 'enable_beta_mode_legal_solution', // Enables beta mode for the legal solution
	EnableBetaModeClientBillingSolution = 'enable_beta_mode_client_billing_solution', // Enables beta mode for the client billing solution
	EnableRetainData = 'enable_solution_retain_data',
	EnableSolutionConfiguration = 'enable_solution_configuration', // Enables configuration settings for solutions
}
